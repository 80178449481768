import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useIntl } from 'react-intl';
import { v4 as uuidv4 } from 'uuid';
import { toAbsoluteUrl } from '../../../../../_metronic/_helpers';
import { Field, Formik, useFormikContext } from 'formik';
import { TextArea, Input, InputVertical, Card, CardBody, CardHeader } from '../../../../../_metronic/_partials/controls'
import * as Yup from 'yup'
import { useMutation, useQuery } from '@apollo/client';
import { useToasts } from 'react-toast-notifications';
import LoadingDialog from '../../../ProductsStore/product-new/LoadingDialog';
import query_cfGetAllFieldDefiend from '../../../../../graphql/query_cfGetAllFieldDefiend';
import { indexToAlphabet } from '../utils';
import { Dropdown } from 'react-bootstrap';
import query_sc_stores_basic from '../../../../../graphql/query_sc_stores_basic';
import mutate_cfUpdateConfigColumns from '../../../../../graphql/mutate_cfUpdateConfigColumns';
import { template } from 'lodash';
import { useHistory } from 'react-router-dom';
import { isJSON } from '../../../../../utils';
import Select from "react-select";

const optionTypes = [{
    value: 'string',
    label: "Định dạng chuỗi"
    },
    {
        value: 'number',
        label: "Định dạng số"
    }
]

const CònigTemplateTable = ({ id, itemUpdate, isCopy, dataStore }) => {
    const { formatMessage } = useIntl();
    const { addToast } = useToasts()
    const history = useHistory()
    const [initialValues, setInitialValues] = useState({});
    const [initValidate, setInitValidate] = useState({})
    const [queue, setQueue] = useState()
    const [loadingState, setLoadingState] = useState(false)
    const [isExpand, setIsExpand] = useState(false)
    const [expandCategory, setExpandCategory] = useState([])
    const [listTemplate, setListTemplate] = useState(itemUpdate?.data?.filter(item => item?.row_name == 'Thông tin danh mục' || !item?.row_name)?.map(item => ({
        ...item,
        temp_id: uuidv4()
    })) || [])
    // const [listFeeTemplate, setListFeeTemplate] = useState(itemUpdate?.data?.filter(item => item?.row_name == 'Thêm phí vận chuyển')?.map(item => ({
    //     ...item,
    //     temp_id: uuidv4()
    // })) || [])
    const bottomEl = useRef(null);

    const firstCardRef = useRef(null);
    const [maxHeight, setMaxHeight] = useState('auto');

    useEffect(() => {
        const handleResize = () => {
            if (firstCardRef.current) {
                const firstCardHeight = firstCardRef.current.offsetHeight;
                // const availableHeight = (window.innerHeight - firstCardHeight - 200) /2;
                const availableHeight = (window.innerHeight - firstCardHeight - 200);
                setMaxHeight(`${availableHeight}px`);
            }
        };

        handleResize();
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const {data: dataFieldDefined, loading: loadingFieldDefined} = useQuery(query_cfGetAllFieldDefiend, {
        variables: {
            type: itemUpdate?.template_type
        },
        fetchPolicy: 'cache-and-network'
    })

    useMemo(() => {
        let validate = []
        let initValue = [];

        (listTemplate || []).forEach((item, index) => {
            validate[`item-template-${item?.temp_id}`] = Yup.string()
                .max(255, formatMessage({ defaultMessage: "Nhập tối đa 255 ký tự." }))
            validate[`name-template-${item?.temp_id}`] = Yup.string().required(formatMessage({ defaultMessage: 'Vui lòng nhập tên danh mục' }))
                .max(255, formatMessage({ defaultMessage: "Nhập tối đa 255 ký tự." }))
            if (!item?.source_data) {
                initValue[`item-template-${item?.temp_id}`] = item?.value || ''
            } else {
                const tempArr = dataStore?.sc_stores?.map(store => {
                    validate[`item-template-${item?.temp_id}-${store?.id}`] = Yup.string()
                        .max(255, formatMessage({ defaultMessage: "Nhập tối đa 255 ký tự." }))
                const sourceData = isJSON(item?.source_data) ? JSON.parse(item?.source_data) : {}
                initValue[`item-template-${item?.temp_id}-${store?.id}`] = sourceData[`${store?.id}`] || ''
                })
                
            }
            initValue[`name-template-${item?.temp_id}`] = item?.column_name || ''
        });

        // (listFeeTemplate || []).forEach((item, index) => {
        //     validate[`item-template-${item?.temp_id}`] = Yup.string()
        //         .max(255, formatMessage({ defaultMessage: "Nhập tối đa 255 ký tự." }))
        //     if (!item?.source_data) {
        //         initValue[`item-template-${item?.temp_id}`] = item?.value || ''
        //     } else {
        //         const tempArr = dataStore?.sc_stores?.map(store => {
        //             validate[`item-template-${item?.temp_id}-${store?.id}`] = Yup.string()
        //                 .max(255, formatMessage({ defaultMessage: "Nhập tối đa 255 ký tự." }))
        //         const sourceData = isJSON(item?.source_data) ? JSON.parse(item?.source_data) : {}
        //         initValue[`item-template-${item?.temp_id}-${store?.id}`] = sourceData[`${store?.id}`] || ''
        //         })
        //     }
        //     initValue[`name-template-${item?.temp_id}`] = listTemplate[index]?.column_name || ''
        // });

        validate['nameTemplate'] = Yup.string().required(formatMessage({ defaultMessage: 'Vui lòng nhập tên mẫu' }))
            .max(255, formatMessage({ defaultMessage: "Nhập tên mẫu tối đa 255 ký tự." }))
        setInitialValues(prev => ({
            ...prev,
            nameTemplate: (isCopy ? `Sao chép ${itemUpdate?.template_name}` : itemUpdate?.template_name) || '',
            ...initValue,
            listTemplate,
            // listFeeTemplate
        }));
        setInitValidate(Yup.object().shape(validate));
    // }, [listTemplate, listFeeTemplate, itemUpdate, dataStore])
    }, [listTemplate, itemUpdate, dataStore])

    const [updateTemplateConfig, { loading }] = useMutation(mutate_cfUpdateConfigColumns,
        { awaitRefetchQueries: true, refetchQueries: ['cfGetAllTemplateExport', 'cfGetConfigTemplateExport'] }
    );
    const handleAddRow = (temp_id) => {
        setLoadingState(true)
        const newTemplate = [...listTemplate];
        // const newFeeTemplate = [...listFeeTemplate];
        const index = newTemplate.findIndex(item => item.temp_id === temp_id);
        const newItem = {
            temp_id: uuidv4(), 
            row_name: 'Thông tin danh mục'
        };
        // const newFeeItem = {
        //     temp_id: uuidv4(), 
        //     row_name: 'Thêm phí vận chuyển'
        // };
        newTemplate.splice(index + 1, 0, newItem);
        // newFeeTemplate.splice(index + 1, 0, newFeeItem);
        setListTemplate(newTemplate);
        // setListFeeTemplate(newFeeTemplate);
        setLoadingState(false)
    };

    const stores = useMemo(() => {
        const channels = dataStore?.op_connector_channels ?? {};
        return dataStore?.sc_stores?.map((store) => {
            const channel = channels?.find(
                (cn) => cn?.code == store?.connector_channel_code
            );
            return {
                ...store,
                logo: channel?.logo_asset_url,
            };
        });
    }, [dataStore]);

    return (
        <div>
            <LoadingDialog show={loadingFieldDefined || loadingState || loading} />
            <Formik initialValues={initialValues} validationSchema={initValidate}
                onSubmit={async (values) => {
                    try{
                        console.log(values)                                                                                
                        const list_columns = listTemplate?.map((item, index) => {
                            const sourceData = !!item?.source_data ? stores?.reduce((acc, store) => {
                                acc[store?.id] = values[`item-template-${item?.temp_id}-${store?.id}`]?.replace(/\s+/g, ' ')?.trim() || '';
                                return acc;
                            }, {}) : null
                            return {
                                column_name: values[`name-template-${item?.temp_id}`]?.replace(/\s+/g, ' ')?.trim() || '',
                                position: index || 0,
                                source_data: sourceData ? JSON.stringify(sourceData) : '',
                                row_name: 'Thông tin danh mục',
                                type: item?.type || 'string',
                                value: !sourceData ? (values[`item-template-${item?.temp_id}`]?.replace(/\s+/g, ' ')?.trim() || '') : ''
                            }
    
                        })
                        // const list_fee_columns = listFeeTemplate?.map((item, index) => {
                        //     const sourceData = !!item?.source_data ? stores?.reduce((acc, store) => {
                        //         acc[store?.id] = values[`item-template-${item?.temp_id}-${store?.id}`]?.replace(/\s+/g, ' ')?.trim() || '';
                        //         return acc;
                        //     }, {}) : null
                        //     return {
                        //         column_name: item?.column_name,
                        //         position: index || 0,
                        //         row_name: 'Thêm phí vận chuyển',
                        //         source_data: sourceData ? JSON.stringify(sourceData) : '',
                        //         type: 'string',
                        //         value: !sourceData ? (values[`item-template-${item?.temp_id}`]?.replace(/\s+/g, ' ')?.trim() || '') : ''
                        //     }
    
                        // })
                        const varibalesCreateTeamplate = {
                            template_name: values['nameTemplate']?.replace(/\s+/g, ' ')?.trim(),
                            template_type: itemUpdate?.template_type,
                            template_code: itemUpdate?.template_code,
                            template_config: itemUpdate?.template_config,
                            template_id: 0,
                            // list_columns: list_columns?.concat(list_fee_columns)
                            list_columns: list_columns
                        }
    
                        const varibalesUpdateTeamplate = {
                            template_name: values['nameTemplate'].replace(/\s+/g, ' ')?.trim(),
                            template_type: itemUpdate?.template_type,
                            template_code: itemUpdate?.template_code,
                            template_config: itemUpdate?.template_config,
                            template_id: itemUpdate?.template_id,
                            // list_columns: list_columns?.concat(list_fee_columns)
                            list_columns: list_columns
                        }
    
                        const { data } = await updateTemplateConfig({
                            variables: !isCopy ? {
                                ...varibalesUpdateTeamplate
                            } : {
                                ...varibalesCreateTeamplate
                            }
                        })
                        if (!!data?.cfUpdateConfigColumns?.success) {
                            addToast(!isCopy ? formatMessage({ defaultMessage: 'Cập nhật mẫu thành công' }) : formatMessage({ defaultMessage: 'Tạo mẫu thành công.' }), { appearance: 'success' })
                            history.push('/setting/setting-finance?tab=settingTemplate')
                            return
                        }
                        addToast(data?.cfUpdateConfigColumns?.message || (!isCopy ? formatMessage({ defaultMessage: 'Cập nhật mẫu không thành công' }) : formatMessage({ defaultMessage: 'Tạo mẫu không thành công.' }), { appearance: 'error' }))
                    } catch (error) {
                        console.log(error)
                    }
                }}
            >
                {({ values, setFieldValue, handleSubmit, errors, setValues, handleBlur, validateForm, setTouched }) => {
                    console.log(values)
                    return (
                                <div>
                                    <Card ref={firstCardRef}>
                                        <CardBody>
                                            <div className='mb-4 row'>
                                                <span className='col-2 text-right mt-2'>{formatMessage({ defaultMessage: 'Tên:' })}</span>
                                                <div className="col-9">
                                                    <Field name={`nameTemplate`}
                                                        component={InputVertical}
                                                        value={values['nameTemplate']}
                                                        placeholder={formatMessage({
                                                            defaultMessage: "Nhập tên",
                                                        })}
                                                        label={""}
                                                        onChangeValue={() => {
                                                            if (!!values[`checkExitsName`]) {
                                                                setFieldValue('checkExitsName', false)
                                                            }
                                                        }}
                                                        required={false}
                                                        customFeedbackLabel={" "}
                                                        cols={["col-0", "col-12"]}
                                                        countChar
                                                        rows={2}
                                                        minChar={"0"}
                                                        maxChar={"255"} />
                                                </div>
                                            </div>
                                        </CardBody>
                                    </Card>
                                    <div className='row'>
                                        <div className={`col-${isExpand ? 8 : 12}`}>
                                            <Card style={{ maxHeight: maxHeight, overflowY: 'auto' }}>
                                                <CardHeader>
                                                    <div className='d-flex justify-content-between align-items-center'>
                                                        <strong style={{fontSize: 16}}>Thông tin danh mục</strong>
                                                    </div>
                                                    <span className='d-flex align-items-center cursor-pointer' onClick={() => setIsExpand(pre => !pre)}>
                                                        {isExpand ? <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-text-indent-left" viewBox="0 0 16 16">
                                                            <path d="M2 3.5a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5m.646 2.146a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1 0 .708l-2 2a.5.5 0 0 1-.708-.708L4.293 8 2.646 6.354a.5.5 0 0 1 0-.708M7 6.5a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5m0 3a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5m-5 3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5"/>
                                                        </svg> : <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-text-indent-right" viewBox="0 0 16 16">
                                                            <path d="M2 3.5a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5m10.646 2.146a.5.5 0 0 1 .708.708L11.707 8l1.647 1.646a.5.5 0 0 1-.708.708l-2-2a.5.5 0 0 1 0-.708zM2 6.5a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5m0 3a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5m0 3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5"/>
                                                        </svg>}
                                                    </span>
                                                </CardHeader>
                                                <CardBody>
                                                    <table className='table-border table-borderless table-vertical-center w-100'>
                                                        <thead
                                                            style={{
                                                                background: "#F3F6F9",
                                                                fontWeight: "bold",
                                                                fontSize: "14px",
                                                                borderRight: '1px solid #d9d9d9',
                                                                borderLeft: '1px solid #d9d9d9'
                                                            }}
                                                        >
                                                            <tr className="font-size-lg">
                                                                <th style={{ fontSize: '14px' }} className='text-center' width="30%">{formatMessage({ defaultMessage: 'Tên danh mục' })}</th>
                                                                <th style={{ fontSize: '14px' }} className='text-center' width="15%">{formatMessage({ defaultMessage: 'Cột tương ứng trên excel' })}</th>
                                                                <th style={{ fontSize: '14px' }} className='text-center' width="30%">{formatMessage({ defaultMessage: 'Nội dung quy định' })}</th>
                                                                <th style={{ fontSize: '14px' }} className='text-center' width="15%">{formatMessage({ defaultMessage: 'Định dạng dữ liệu' })}</th>
                                                                <th style={{ fontSize: '14px' }} className='text-center' width='10%'> {formatMessage({ defaultMessage: 'Thao tác' })}</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {listTemplate?.map((item, index) => {
                                                                const isCustom = !!item?.source_data
                                                                return (
                                                                    <tr ref={bottomEl} key={item?.temp_id}>
                                                                        <td className='p-2'>
                                                                            <Field name={`name-template-${item?.temp_id}`}
                                                                                component={InputVertical}
                                                                                placeholder={formatMessage({
                                                                                    defaultMessage: "Nhập tên danh mục",
                                                                                })}
                                                                                // onChangeValue={(e) => {
                                                                                //     let newFeeTemplate = listFeeTemplate?.map((_item, _index) => {
                                                                                //         if (_index == index) {
                                                                                //             return {
                                                                                //                 ..._item, column_name:e.target.value
                                                                                //             }
                                                                                //         }
                                                                                //         return _item
                                                                                //     })
                                                                                //     setListFeeTemplate(newFeeTemplate)
                                                                                // }}
                                                                                label={""}
                                                                                required={false}
                                                                                customFeedbackLabel={" "}
                                                                                />
                                                                            <label key={item?.temp_id} className="checkbox checkbox-outline checkbox-primary mt-3 mb-1">
                                                                                <input type="checkbox" name={`is-custom-${item?.temp_id}`}
                                                                                    defaultChecked={isCustom}
                                                                                    onChange={(e) => {
                                                                                        if (!item?.source_data) {
                                                                                            delete errors[`item-template-${item?.temp_id}`]
                                                                                            delete initValidate[`item-template-${item?.temp_id}`]
                                                                                            delete initialValues[`item-template-${item?.temp_id}`]
                                                                                            const newTemplate = listTemplate?.map(_item => {
                                                                                                if(_item.temp_id === item?.temp_id){
                                                                                                    return {
                                                                                                        ..._item,
                                                                                                        source_data: JSON.stringify({})
                                                                                                    }
                                                                                                }
                                                                                                return _item
                                                                                            })
                                                                                            setListTemplate(newTemplate)
                                                                                        } else {
                                                                                            const tempArr = dataStore?.sc_stores?.map(store => {
                                                                                                delete errors[`item-template-${item?.temp_id}-${store?.id}`]
                                                                                                delete initValidate[`item-template-${item?.temp_id}-${store?.id}`]
                                                                                                delete initialValues[`item-template-${item?.temp_id}-${store?.id}`]
                                                                                            })
                                                                                            const newTemplate = listTemplate?.map(_item => {
                                                                                                if(_item.temp_id === item?.temp_id){
                                                                                                    return {
                                                                                                        ..._item,
                                                                                                        source_data: ""
                                                                                                    }
                                                                                                }
                                                                                                return _item
                                                                                            })
                                                                                            setListTemplate(newTemplate)
                                                                                        }
                                                                                        
                                                                                    }} />
                                                                                <span></span>
                                                                                &ensp;{formatMessage({ defaultMessage: 'Quy định theo từng gian hàng' })}
                                                                            </label>
                                                                        </td>
                                                                        <td style={{textAlign: 'center'}}>{indexToAlphabet(index)}</td>
                                                                        <td className='p-2'>
                                                                            {!isCustom ? <Field name={`item-template-${item?.temp_id}`}
                                                                                component={TextArea}
                                                                                placeholder={formatMessage({ defaultMessage: "Nhập nội dung tại đây" })}
                                                                                label={""}
                                                                                onBlurChange={() => setQueue(`item-template-${item?.temp_id}`)}
                                                                                required={false}
                                                                                customFeedbackLabel={" "}
                                                                                cols={["col-0", "col-12"]}
                                                                                countChar
                                                                                rows={3}
                                                                                maxChar={"255"} />
                                                                                : stores?.map(store => (
                                                                                    <>
                                                                                        <div>
                                                                                            {!!store?.logo && <img src={store?.logo} style={{ width: 15, height: 15, marginRight: 4 }} alt=""/>}
                                                                                            {store?.name}
                                                                                        </div>
                                                                                        <Field name={`item-template-${item?.temp_id}-${store?.id}`}
                                                                                            component={TextArea}
                                                                                            placeholder={formatMessage({ defaultMessage: "Nhập nội dung tại đây" })}
                                                                                            label={""}
                                                                                            onBlurChange={() => setQueue(`item-template-${item?.temp_id}-${store?.id}`)}
                                                                                            required={false}
                                                                                            customFeedbackLabel={" "}
                                                                                            cols={["col-0", "col-12"]}
                                                                                            countChar
                                                                                            rows={3}
                                                                                            maxChar={"255"} />
                                                                                    </>
                                                                                ))
                                                                            }
                                                                        </td>
                                                                        <td>
                                                                            <Select 
                                                                                options={optionTypes}
                                                                                className='w-100'
                                                                                value={optionTypes?.find(opt => opt?.value == item?.type) || optionTypes[0]}
                                                                                onChange={value => {
                                                                                    const newTemplate = listTemplate?.map(_item => {
                                                                                        if (_item?.temp_id == item?.temp_id) {
                                                                                            return {
                                                                                                ..._item,
                                                                                                type: value?.value
                                                                                            }
                                                                                        }
                                                                                        return _item
                                                                                    })
                                                                                    setListTemplate(newTemplate)
                                                                                }}
                                                                                formatOptionLabel={(option, labelMeta) => {
                                                                                    return <div>
                                                                                        {option.label}
                                                                                    </div>
                                                                                }}
                                                                            />
                                                                        </td>
                                                                        <td className='text-center'>
                                                                            <Dropdown drop='down' >
                                                                                <Dropdown.Toggle className='btn-outline-primary' >
                                                                                    {formatMessage({ defaultMessage: 'Chọn' })}
                                                                                </Dropdown.Toggle>
                                                                                
                                                                                <Dropdown.Menu>
                                                                                    <Dropdown.Item className="mb-1 d-flex" onClick={() => handleAddRow(item?.temp_id, 'Thông tin danh mục')}>{formatMessage({ defaultMessage: 'Thêm hàng' })}</Dropdown.Item>
                                                                                    {listTemplate?.length > 1 && <Dropdown.Item className="mb-1 d-flex" onClick={() => {
                                                                                        delete errors[`item-template-${item?.temp_id}`]
                                                                                        delete errors[`name-template-${item?.temp_id}`]
                                                                                        const newTemplate = listTemplate?.filter(_item => _item?.temp_id !== item?.temp_id)
                                                                                        // const newFeeTemplate = listFeeTemplate?.filter((_item, _index) => _index != index)
                                                                                        setListTemplate(newTemplate)
                                                                                        // setListFeeTemplate(newFeeTemplate)
                                                                                        delete initValidate[`item-template-${item?.temp_id}`]
                                                                                        delete initValidate[`name-template-${item?.temp_id}`]
                                                                                        delete initialValues[`item-template-${item?.temp_id}`]
                                                                                        delete initialValues[`name-template-${item?.temp_id}`]
                                                                                    }}>{formatMessage({ defaultMessage: 'Xóa' })}</Dropdown.Item>}
                                                                                    
                                                                                </Dropdown.Menu>
                                                                            </Dropdown>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })}
                                                        </tbody>
                                                    </table>
                                                </CardBody>
                                            </Card>
                                            {/* <Card style={{ maxHeight: maxHeight, overflowY: 'auto' }}>
                                                <CardHeader>
                                                    <div className='d-flex justify-content-between align-items-center'>
                                                        <strong style={{fontSize: 16}}>Thêm phí vận chuyển</strong>
                                                        <span className={`switch prd-new ml-4`}>
                                                            <label className="product-stores-swbtn">
                                                                <input
                                                                    type={'checkbox'}
                                                                    style={{ background: '#F7F7FA', border: 'none' }}
                                                                    onChange={e => {
                                                                        if (listFeeTemplate.length) {
                                                                            listFeeTemplate.forEach(item => {
                                                                                delete errors[`item-template-${item?.temp_id}`];
                                                                                dataStore?.sc_stores?.forEach(store => {
                                                                                    delete errors[`item-template-${item?.temp_id}-${store?.id}`];
                                                                                });
                                                                            });
                                                                            setListFeeTemplate([])
                                                                        } else {
                                                                            let formValues = { ...values };
                                                                            const newTemplate = listTemplate?.map(item => {
                                                                                return {
                                                                                    ...item,
                                                                                    temp_id: uuidv4(),
                                                                                    row_name: 'Thêm phí vận chuyển'
                                                                                }
                                                                            });
                                                                            setListFeeTemplate(newTemplate);
                                                                            (newTemplate || []).forEach((item, index) => {
                                                                                if (!item?.source_data) {
                                                                                    formValues[`item-template-${item?.temp_id}`] = values[`item-template-${listTemplate[index]?.temp_id}`]
                                                                                } else {
                                                                                    const tempArr = dataStore?.sc_stores?.map(store => {
                                                                                        formValues[`item-template-${item?.temp_id}-${store?.id}`] = values[`item-template-${listTemplate[index]?.temp_id}-${store?.id}`] 
                                                                                    })
                                                                                    
                                                                                }
                                                                            });
                                                                            setValues(formValues)
                                                                        }
                                                                    }}
                                                                checked={listFeeTemplate.length || false}
                                                                />
                                                                <span></span>
                                                            </label>
                                                        </span>
                                                    </div>
                                                </CardHeader>
                                                <CardBody>
                                                    <table className='table-border table-borderless table-vertical-center w-100'>
                                                        <thead
                                                            style={{
                                                                background: "#F3F6F9",
                                                                fontWeight: "bold",
                                                                fontSize: "14px",
                                                                borderRight: '1px solid #d9d9d9',
                                                                borderLeft: '1px solid #d9d9d9'
                                                            }}
                                                        >
                                                            <tr className="font-size-lg">
                                                                <th style={{ fontSize: '14px' }} className='text-center' width="30%">{formatMessage({ defaultMessage: 'Tên danh mục' })}</th>
                                                                <th style={{ fontSize: '14px' }} className='text-center' width="15%">{formatMessage({ defaultMessage: 'Cột tương ứng trên excel' })}</th>
                                                                <th style={{ fontSize: '14px' }} className='text-center' width="40%">{formatMessage({ defaultMessage: 'Nội dung quy định' })}</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {listFeeTemplate?.map((item, index) => {
                                                                const isCustom = !!item?.source_data
                                                                return (
                                                                    <tr ref={bottomEl} key={item?.temp_id}>
                                                                        <td className='p-2'>
                                                                            <p>{item?.column_name}</p>
                                                                            <label key={item?.temp_id} className="checkbox checkbox-outline checkbox-primary mt-3 mb-1">
                                                                                <input type="checkbox" name={`is-custom-${item?.temp_id}`}
                                                                                    defaultChecked={isCustom}
                                                                                    onChange={(e) => {
                                                                                        if (!item?.source_data) {
                                                                                            delete errors[`item-template-${item?.temp_id}`]
                                                                                            delete initValidate[`item-template-${item?.temp_id}`]
                                                                                            delete initialValues[`item-template-${item?.temp_id}`]
                                                                                            const newFeeTemplate = listFeeTemplate?.map(_item => {
                                                                                                if(_item.temp_id === item?.temp_id){
                                                                                                    return {
                                                                                                        ..._item,
                                                                                                        source_data: JSON.stringify({})
                                                                                                    }
                                                                                                }
                                                                                                return _item
                                                                                            })
                                                                                            setListFeeTemplate(newFeeTemplate)
                                                                                        } else {
                                                                                            const tempArr = dataStore?.sc_stores?.map(store => {
                                                                                                delete errors[`item-template-${item?.temp_id}-${store?.id}`]
                                                                                                delete initValidate[`item-template-${item?.temp_id}-${store?.id}`]
                                                                                                delete initialValues[`item-template-${item?.temp_id}-${store?.id}`]
                                                                                            })
                                                                                            const newFeeTemplate = listFeeTemplate?.map((_item, _index) => {
                                                                                                if(_index === index){
                                                                                                    return {
                                                                                                        ..._item,
                                                                                                        source_data: ''
                                                                                                    }
                                                                                                }
                                                                                                return _item
                                                                                            })
                                                                                            setListFeeTemplate(newFeeTemplate)
                                                                                        }
                                                                                        
                                                                                    }} />
                                                                                <span></span>
                                                                                &ensp;{formatMessage({ defaultMessage: 'Quy định theo từng gian hàng' })}
                                                                            </label>
                                                                        </td>
                                                                        <td style={{textAlign: 'center'}}>{indexToAlphabet(index)}</td>
                                                                        <td className='p-2'>
                                                                            {!isCustom ? <Field name={`item-template-${item?.temp_id}`}
                                                                                component={TextArea}
                                                                                placeholder={formatMessage({ defaultMessage: "Nhập nội dung tại đây" })}
                                                                                label={""}
                                                                                onBlurChange={() => setQueue(`item-template-${item?.temp_id}`)}
                                                                                required={false}
                                                                                customFeedbackLabel={" "}
                                                                                cols={["col-0", "col-12"]}
                                                                                countChar
                                                                                rows={3}
                                                                                maxChar={"255"} />
                                                                                : stores?.map(store => (
                                                                                    <>
                                                                                        <div>
                                                                                            {!!store?.logo && <img src={store?.logo} style={{ width: 15, height: 15, marginRight: 4 }} alt=""/>}
                                                                                            {store?.name}
                                                                                        </div>
                                                                                        <Field name={`item-template-${item?.temp_id}-${store?.id}`}
                                                                                            component={TextArea}
                                                                                            placeholder={formatMessage({ defaultMessage: "Nhập nội dung tại đây" })}
                                                                                            label={""}
                                                                                            onBlurChange={() => setQueue(`item-template-${item?.temp_id}-${store?.id}`)}
                                                                                            required={false}
                                                                                            customFeedbackLabel={" "}
                                                                                            cols={["col-0", "col-12"]}
                                                                                            countChar
                                                                                            rows={3}
                                                                                            maxChar={"255"} />
                                                                                    </>
                                                                                ))
                                                                            }
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })}
                                                        </tbody>
                                                    </table>
                                                </CardBody>
                                            </Card> */}
                                        </div>
                                        {isExpand && <div className='col-4'>
                                            <Card style={{ maxHeight: maxHeight, overflowY: 'auto' }}>
                                                <CardBody>
                                                    <p className='mb-4'>{formatMessage({defaultMessage: 'Dữ liệu cần chèn vào nội dung quy định'})}</p>
                                                    {dataFieldDefined?.cfGetAllFieldDefiend?.data?.map((item, index) => {
                                                        return (
                                                            <div className='mb-4'>
                                                                <div className='d-flex align-items-center justify-content-between cursor-pointer' onClick={() => {
                                                                    if(expandCategory?.includes(item?.group_name)) {
                                                                        const newExpand = expandCategory?.filter(cat => cat != item?.group_name)
                                                                        setExpandCategory(newExpand)
                                                                    } else {
                                                                        setExpandCategory([...expandCategory, item?.group_name])
                                                                    }
                                                                }}>
                                                                    <strong>{item?.group_name}</strong>
                                                                    {expandCategory?.includes(item?.group_name) ? 
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-chevron-up" viewBox="0 0 16 16">
                                                                            <path fill-rule="evenodd" d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708z"
                                                                                stroke="currentColor" stroke-width="1.5" />
                                                                        </svg>
                                                                    
                                                                        : <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16">
                                                                            <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708"
                                                                                stroke="currentColor" stroke-width="1.5"
                                                                            />
                                                                        </svg>
                                                                    }
                                                                </div>
                                                                {!!expandCategory?.includes(item?.group_name) && item?.fields?.map(field => (<div className='mt-2 mb-2 d-flex align-items-center'>
                                                                    <div onClick={() => {
                                                                        try {
                                                                            if (!listTemplate.length) return
                                                                            const domElm = document.querySelector(`[name='${queue}']`)
                                                                            if (!domElm) return
                                                                            const lengthInsertStr = field?.source_data?.length;
                                                                            const positionAreaForcus = domElm?.selectionEnd;
                                                                            const newPositionInsert = positionAreaForcus + lengthInsertStr;
                                                                            setFieldValue(queue, `${(values[queue] || '').slice(0, positionAreaForcus)}${field?.source_data}${(values[queue] || '').slice(positionAreaForcus)}`)
                                                                            setTimeout(() => {
                                                                                domElm.focus();
                                                                                domElm.setSelectionRange(newPositionInsert, newPositionInsert);
                                                                            }, 100)
                                                                        } catch {

                                                                        }

                                                                    }}
                                                                        style={{ cursor: 'pointer', width: 'max-content', background: 'rgb(0, 219, 109)', padding: '5px', borderRadius: '5px', fontWeight: 'bold', color: 'white' }}>
                                                                        {field?.source_data}
                                                                    </div>
                                                                    <span className='mx-2'>{`Chèn ${field?.name}`}</span>
                                                                </div>))}
                                                            </div>
                                                        )
                                                    })}
                                                </CardBody>
                                            </Card>
                                        </div>}
                                    </div>
                                        <div className="form-group mt-4" style={{textAlign: 'right'}}>
                                            <button onClick={() => {
                                                history.push('/setting/setting-finance?tab=settingTemplate')
                                            }} type="button" className="btn btn-light btn-elevate mr-3" style={{ width: 100, background: 'gray', color: 'white' }}>
                                                {formatMessage({ defaultMessage: "Hủy" })}
                                            </button>
                                            <button disabled={!listTemplate?.length || loading}
                                                onClick={handleSubmit} type="button" className="btn btn-primary mr-3" style={{ width: 100 }} >
                                                {!isCopy ? formatMessage({ defaultMessage: "Cập nhật" }) : formatMessage({ defaultMessage: "Tạo" })}
                                            </button>
                                        </div>
                                </div>
                    )
                }}
            </Formik>
        </div>

    )
}

export default CònigTemplateTable
